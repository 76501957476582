<template>
  <div class="text-gray-700 font-bold text-4xl">
    Try searching for "heart" 👆
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>
